@media all and (min-width:320px){
    header>nav>a.nav-link::after{
        content: "|";
        color: rgba(21, 81, 193, 0.3);
        margin: 5px;
    }
}

body{
    margin: 1%;
    padding: 1%;
    height: 100vh;
  }

header{
    padding: 0% 0% 3% 0% !important;
    border-radius: 5px;
  }

/* @media all and (min-width:320px){
    h1.typografyAssistfolk::after{
        content: "Folk"!important;
        color: #ffffff !important; 
        font-family: sans-serif, Verdana, Geneva, Tahoma!important;
        font-style: oblique!important;
    }
} */

/* h1.typografyAssistfolk{
    font-family: sans-serif, Verdana, Geneva, Tahoma!important;
    font-style: normal!important;
    margin: 0 20% 0 0;
} */

header nav a img{
    width: 2vw;   /* como hacer para q sea responsive ? */
    margin: 4px;    /*Como hacer para q funcione con % ? */
}

.nav-link{
    color: #4285f4 !important;
    font-size: small !important;
}



.col-sm{
    /* border: 1px solid black; */
    
}

.container{
    margin-top: 3%;
}


p{
    font-size: 0.8rem ;
}

.img-fluid{
    margin-top: 3%;
    margin-bottom: 3%;
}

header div{
  
    position: relative; 
    width: 100%; 
    height: 0; 
    padding-top: 25.0000%; 
    padding-bottom: 48px; 
    box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); 
    margin-top: 1.6em; 
    margin-bottom: 0.9em; 
    overflow: hidden; 
    border-radius: 8px; 
    will-change: transform;
}

header div img{
    position: absolute; 
    width: 100%; 
    height: 100%; 
    top: 0; 
    left: 0; 
    border: none; 
    padding: 0;
    margin: 0;
}

footer a{
    text-decoration: none;
}

#signIn{
    
    color:#000000 ;
    margin-left: 6%;

}

#signUp{
    color:#4285f4;
    margin-left: 6%;

}

.sign{
    text-decoration: none;
    font-size: 0.8rem ;
    float: right;
    margin: 1%;
}


