
  * {box-sizing: border-box}

  /* Full-width input fields */
    input[type=text], input[type=password] {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: none;
    background: #f1f1f1;
  }
  
  input[type=text]:focus, input[type=password]:focus {
    background-color: #ddd;
    outline: none;
  }
  
  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }
  
  /* Set a style for all buttons */
  button {
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
  }
  
  button:hover {
    opacity:1;
  }
  
  /* Extra styles for the cancel button */
  .cancelbtn {
    padding: 14px 20px;
    background-color: #918888;
  }

  /* Extra styles for the signup button */
  .signupbtn {
    padding: 14px 20px;
    background-color: #4285f4;  
  }
  
  /* Float cancel and signup buttons and add an equal width */
  .cancelbtn, .signupbtn {
    float: left;
    width: 50%;
  }

  /* Extra styles for the next Login button */
  .nextLogInButton {
    padding: 14px 20px;
    background-color: #4285f4;
  }

  /* Extra styles for the forgot password button */
  .forgotPasswordButton {
    padding: 14px 20px;
    background-color: #918888;  
  }

  /* Float next and forgotPasssword buttons and add an equal width */
  .nextLogInButton, .forgotPasswordButton {
    display: left; /*Block si quiero q sean botones en diferentes lineas*/
    width: 100%;
  }
  
  /* Add padding to container elements */
  .container {
    padding: 16px;
  }
  
  /* Clear floats */
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
  
  /* Change styles for cancel button and signup button on extra small screens */
  @media screen and (max-width: 300px) {
    .cancelbtn, .signupbtn {
      width: 100%;
    }
  }