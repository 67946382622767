@media all and (min-width:320px){
    header>nav>a.nav-link::after{
        content: "|";
        color: rgba(21, 81, 193, 0.3);
        margin: 5px;
    }
}

body{
    margin: 1%;
    padding: 1%;
    height: 100vh;
  }

header{
    padding: 0% 0% 3% 0% !important;
    border-radius: 5px;
  }

header nav a img{
    width: 2vw;   /* como hacer para q sea responsive ? */
    margin: 4px;    /*Como hacer para q funcione con % ? */
}

.nav-link{
    color: #4285f4 !important;
    font-size: small !important;
}

header div{
  
    position: relative; 
    width: 100%; 
    height: 0; 
    padding-top: 25.0000%; 
    padding-bottom: 48px; 
    box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); 
    margin-top: 1.6em; 
    margin-bottom: 0.9em; 
    overflow: hidden; 
    border-radius: 8px; 
    will-change: transform;
}

header div img{
    position: absolute; 
    width: 100%; 
    height: 100%; 
    top: 0; 
    left: 0; 
    border: none; 
    padding: 0;
    margin: 0;
}

footer a{
    text-decoration: none;
}

.notesLeft{
    display:inline-block;
    width: 18%;
    height: 100vh;
    float: left;
    background-color: #fafafa;
    overflow: auto;
}


/*Styles Note*/
.note{
    width: 80%;
    height: 100%;
    /* border: 5px solid green; */
}

.tags{
    border: none;
    margin-left: 2px;
    background-color: #cac5c5;
}

/*To make it highlighted when I hover over tag*/
.tags:hover{ 
    font-weight: bold;
}

.selected {
    background-color: #4285f4; 
}

.close {
    margin-left: 5px;
    font-weight: lighter;
}

.close:hover {
    margin-left: 5px;
    font-weight: bolder;
}

.addNote{
    float:right;
    width: 5%;
}

.addNoteDisabled {
    float:right;
    width: 5%;
    pointer-events: none;
    opacity: 50%;
}

.saveNoteButton{
    float:right;
    width: 5%;
}

.saveNoteButtonDisabled {
    float:right;
    width: 5%;
    pointer-events: none;
    opacity: 50%;
}

